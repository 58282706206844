import { FC } from "react";

interface CreatedVideosIconProps {
    className?: string;
    style?: Object;
}

const CreatedVideosIcon: FC<CreatedVideosIconProps> = (props: CreatedVideosIconProps) => {
    return (
        <svg className={props.className} style={props.style} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35 0C15.6333 0 0 15.6333 0 35C0 54.3667 15.6333 70 35 70C54.3667 70 70 54.3667 70 35C70 15.6333 54.3667 0 35 0ZM48.3 36.1667L27.5333 48.7667C26.6 49.2333 25.4333 48.7667 25.4333 47.6V22.4C25.4333 21.4667 26.6 20.7667 27.5333 21.2333L48.3 33.8333C49.2333 34.3 49.2333 35.7 48.3 36.1667Z"
                fill="white"
            />
        </svg>
    );
};

export default CreatedVideosIcon;
