import { FC } from "react";

interface RecipientsTabIconProps {}

const RecipientsTabIcon: FC<RecipientsTabIconProps> = (props: RecipientsTabIconProps) => {
    return (
        <svg width="25" height="20" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.5476 8.67225C23.8434 7.83493 24.7206 6.37959 24.7206 4.70494C24.7007 2.11324 22.5875 0 19.9758 0C17.9822 0 16.2876 1.23604 15.5898 2.99043C18.042 3.70813 19.8761 5.96093 19.8961 8.65231C19.8961 9.86842 19.5173 11.0247 18.8594 12.0016C21.4511 13.118 23.2055 15.6699 23.2055 18.6404V20.9131C25.9766 20.5742 27.5516 19.7967 27.6712 19.7368L27.9902 19.5774H28.03V14.673C28.03 11.5231 25.6178 8.95136 22.5476 8.67225Z"
                fill="white"
            />
            <path
                d="M4.74482 18.6204C4.74482 15.6499 6.4992 13.0981 9.09091 12.0016C8.43301 11.0447 8.05423 9.88836 8.05423 8.65231C8.05423 5.94099 9.90829 3.64833 12.4003 2.95056C11.7026 1.23604 10.008 0 8.01435 0C5.40271 0 3.30941 2.11324 3.30941 4.70494C3.30941 6.37959 4.16667 7.83493 5.48246 8.67225C2.41228 8.95136 0 11.5231 0 14.673V19.5574L0.0199362 19.6372L0.358852 19.7368C1.93381 20.2352 3.38915 20.5542 4.74482 20.7536V18.6204Z"
                fill="white"
            />
            <path
                d="M5.94141 18.6203V23.5047L5.96134 23.5844L6.30026 23.6841C9.47011 24.6809 12.2213 24.9999 14.494 24.9999C18.9199 24.9999 21.4916 23.7439 21.6511 23.6642L21.9701 23.5047H22.01V18.6203C22.01 15.4704 19.5977 12.8986 16.5275 12.6195C17.8234 11.7822 18.7006 10.3269 18.7006 8.65221C18.6806 6.0605 16.5674 3.94727 13.9558 3.94727C11.3441 3.94727 9.25082 6.0605 9.25082 8.65221C9.25082 10.3269 10.1081 11.7822 11.4239 12.6195C8.35369 12.8986 5.94141 15.4704 5.94141 18.6203Z"
                fill="white"
            />
        </svg>
    );
};

export default RecipientsTabIcon;
