import { FC } from "react";
import { H } from "highlight.run";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { logError } from "../../Common/Helper";
import { Paths } from "../../Common/AppRoutes";

interface ErrorPageProps {
    error: any;
}

const ErrorPage: FC<ErrorPageProps> = props => {
    H.getSessionDetails().then(({ url, urlWithTimestamp }) => {
        logError(`Critical UI App Error`, props.error, { referrer: window.location.href, highlight_session: urlWithTimestamp });
    });

    const isWhitelistedLoggedInUser = () => {
        return localStorage.getItem("last_whitelisted_status") === "true";
    };

    const navToInbox = () => {
        window.location.href = Paths.myInbox;
    };

    const navToHome = () => {
        window.location.href = Paths.landing;
    };

    return (
        <div className="container p-5 mt-5 text-center">
            <ReportProblemRoundedIcon style={{ color: "#dd4f5d", fontSize: "3rem" }} />
            <div className="h2 mt-5">Oops! Something Went Wrong</div>
            <div className="p-4">
                We're sorry, but it seems like something unexpected has occurred.
                <br /> Our team is already on it, working hard to resolve the issue as quickly as possible.
            </div>
            {isWhitelistedLoggedInUser() && (
                <button className="btn btn-primary mt-5" onClick={navToInbox}>
                    My Inbox
                </button>
            )}
            {!isWhitelistedLoggedInUser() && (
                <button className="btn btn-primary mt-5" onClick={navToHome}>
                    Home
                </button>
            )}
        </div>
    );
};

export default ErrorPage;
