export const APP_VERSION = process.env.REACT_APP_VERSION;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
export const BACKEND_SERVERLESS_URL = process.env.REACT_APP_BACKEND_SERVERLESS_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const DEEP_AR_API_KEY = process.env.REACT_APP_DEEP_AR_API_KEY;
export const HIGHLIGHT_PROJECT = process.env.REACT_APP_HIGHLIGHT_PROJECT;
export const REACT_APP_AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;
export const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const APP_TITLE = "Emovid - AI Enhanced Video Messaging for Business";
export const EMOVID_PUBLIC_BUCKET_URL = "https://emovid-public.s3.us-west-1.amazonaws.com/";
export const EMAIL_REGEX =
    /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
export const SECURE_IMAGE_URL = "https://emovid-public.s3.us-west-1.amazonaws.com/images/secure_video_image_small.jpg";
export const HALLUCINATION_TEXT_REPLACEMENT = "Sorry, we have removed the AI transcription because it didn't meet our accuracy standards".toLowerCase();
export const ALLOWED_PROMO_CODES = process.env.REACT_APP_ALLOWED_PROMO_CODES ? process.env.REACT_APP_ALLOWED_PROMO_CODES.split(",") : [];
export const TRY_NOW_EMOVID_ID = process.env.REACT_APP_TRY_NOW_EMOVID_ID;
export const MAX_FILE_SIZE_IN_MB = 5; // 5MB;
export const START_PRIVATE_THREAD_BUTTON_TEXT = "Reply";
export const SHOULD_WHITELIST_USER_AFTER_LOGIN_KEY = `whitelistUserAfterResponseLogin`;
export const SUPPORTED_LANGUAGES_OBJECTS = [
    {
        key: "Original",
        value: "Original"
    },
    {
        key: "English",
        value: "English"
    },
    {
        key: "Spanish",
        value: "Español"
    },
    {
        key: "Arabic",
        value: "العربية"
    },
    {
        key: "Hindi",
        value: "हिन्दी"
    },
    {
        key: "Chinese",
        value: "中文"
    },
    {
        key: "French",
        value: "Français"
    },
    {
        key: "Taiwanese",
        value: "台語"
    },
    {
        key: "Korean",
        value: "한국어"
    },
    {
        key: "German",
        value: "Deutsch"
    },
    {
        key: "Japanese",
        value: "日本語"
    },
    {
        key: "Albanian",
        value: "Shqiptar"
    },
    {
        key: "Amharic",
        value: "አማርኛ"
    },
    {
        key: "Arabic",
        value: "عربى"
    },
    {
        key: "Armenian",
        value: "հայերեն"
    },
    {
        key: "Bengali",
        value: "বাংলা"
    },
    {
        key: "Bosnian",
        value: "Bosanski"
    },
    {
        key: "Bulgarian",
        value: "български"
    },
    {
        key: "Burmese",
        value: "မြန်မာ"
    },
    {
        key: "Catalan",
        value: "Català"
    },
    {
        key: "Croatian",
        value: "Hrvatski"
    },
    {
        key: "Czech",
        value: "Čeština"
    },
    {
        key: "Danish",
        value: "Dansk"
    },
    {
        key: "Dutch",
        value: "Nederlands"
    },
    {
        key: "Estonian",
        value: "Eesti"
    },
    {
        key: "Finnish",
        value: "Suomi"
    },
    {
        key: "Georgian",
        value: "ქართული"
    },
    {
        key: "Greek",
        value: "Ελληνικά"
    },
    {
        key: "Hungarian",
        value: "Magyar"
    },
    {
        key: "Icelandic",
        value: "Íslenska"
    },
    {
        key: "Indonesian",
        value: "Bahasa Indonesia"
    },
    {
        key: "Italian",
        value: "Italiano"
    },
    {
        key: "Kannada",
        value: "ಕನ್ನಡ"
    },
    {
        key: "Kazakh",
        value: "Қазақ"
    },
    {
        key: "Latvian",
        value: "Latviešu"
    },
    {
        key: "Lithuanian",
        value: "Lietuvių"
    },
    {
        key: "Macedonian",
        value: "Македонски"
    },
    {
        key: "Malay",
        value: "Bahasa Melayu"
    },
    {
        key: "Mongolian",
        value: "Монгол"
    },
    {
        key: "Norwegian",
        value: "Norsk"
    },
    {
        key: "Persian",
        value: "فارسی"
    },
    {
        key: "Polish",
        value: "Polski"
    },
    {
        key: "Portuguese",
        value: "Português"
    },
    {
        key: "Punjabi",
        value: "ਪੰਜਾਬੀ"
    },
    {
        key: "Romanian",
        value: "Română"
    },
    {
        key: "Russian",
        value: "Русский"
    },
    {
        key: "Serbian",
        value: "Српски"
    },
    {
        key: "Slovak",
        value: "Slovenčina"
    },
    {
        key: "Slovenian",
        value: "Slovenščina"
    },
    {
        key: "Somali",
        value: "Soomaali"
    },
    {
        key: "Swahili",
        value: "Kiswahili"
    },
    {
        key: "Swedish",
        value: "Svenska"
    },
    {
        key: "Tagalog",
        value: "Tagalog"
    },
    {
        key: "Tamil",
        value: "தமிழ்"
    },
    {
        key: "Telugu",
        value: "తెలుగు"
    },
    {
        key: "Thai",
        value: "ไทย"
    },
    {
        key: "Turkish",
        value: "Türkçe"
    },
    {
        key: "Ukrainian",
        value: "Українська"
    },
    {
        key: "Urdu",
        value: "اردو"
    },
    {
        key: "Vietnamese",
        value: "Tiếng Việt"
    }
];
