import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, ListItemIcon, MenuItem } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Paths, replaceEventIdInPath } from "./AppRoutes";
import HttpService from "../Services/HttpService";
import ToastService from "./ToastService";
import { AuthContext } from "../Contexts/AuthContext";
import { pushTagEvent } from "./Helper";
import Login from "../Components/Login/Login";
import Popup from "./Popup";

interface CreateEmovidButtonProps {
    location: string;
    className?: string;
    buttonText?: string;
    isMenuButton?: boolean;
    onSuccess?: Function;
    onCreatePersona?: Function;
}

const CreateEmovidButton: FC<CreateEmovidButtonProps> = (props: CreateEmovidButtonProps) => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [showSignupToPostDialog, setShowSignupToPostDialog] = useState<boolean>(false);

    const recordNowClicked = (event: any = null) => {
        if (event) event.stopPropagation();
        if (props.onCreatePersona) {
            props.onCreatePersona();
            return;
        }
        if (!authContext.user) {
            setShowSignupToPostDialog(true);
            return;
        }
        setLoading(true);
        HttpService.postFormData(
            `/v1/event/create`,
            {
                creator_id: authContext.user?.userId,
                permissions: {
                    is_public: false,
                    is_active: true,
                    is_creator_a_contributor: true
                },
                title: ""
            },
            false,
            true
        )
            .then(data => {
                pushTagEvent("e_btn_create_emovid", { location: props.location, event_id: data.event.id, post_id: data.event.CreatorPosts[0].id });
                if (props.onSuccess) props.onSuccess();
                setLoading(false);
                navigate(replaceEventIdInPath(Paths.recordEmovid, data.event.id));
            })
            .catch(error => {
                setLoading(false);
                ToastService.alertableError(error.message || "Unable to create Emovid. Please try again.", error);
            });
    };

    const handleLoginSuccess = async () => {
        setShowSignupToPostDialog(false);
        recordNowClicked();
    };

    return (
        <>
            {!props.isMenuButton && (
                <button className={props.className || "btn btn-primary"} onClick={recordNowClicked}>
                    {props.buttonText || "New Video"}
                </button>
            )}
            {props.isMenuButton && (
                <MenuItem onClick={recordNowClicked}>
                    <ListItemIcon>
                        <AddRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Create New Video
                </MenuItem>
            )}
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
                {loading && <CircularProgress color="inherit" />}
            </Backdrop>
            <Popup isOpen={showSignupToPostDialog} onClose={() => setShowSignupToPostDialog(false)} className="login-popup">
                <Login handleClose={handleLoginSuccess} title={`Please login to create\n an Emovid`}></Login>
            </Popup>
        </>
    );
};

export default CreateEmovidButton;
