import { FC } from "react";

interface ResponsesTabIconProps {}

const ResponsesTabIcon: FC<ResponsesTabIconProps> = (props: ResponsesTabIconProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.0238 0H2.97619C1.33333 0 0 1.33333 0 2.97619V22.0238C0 23.6667 1.33333 25 2.97619 25H22.0238C23.6667 25 25 23.6667 25 22.0238V2.97619C25 1.33333 23.6667 0 22.0238 0ZM13.0952 1.19048H17.8571V4.7619H13.0952V1.19048ZM7.14286 1.19048H11.9048V4.7619H7.14286V1.19048ZM1.19048 2.97619C1.19048 1.9881 1.9881 1.19048 2.97619 1.19048H5.95238V4.7619H1.19048V2.97619ZM5.95238 23.8095H2.97619C1.9881 23.8095 1.19048 23.0119 1.19048 22.0238V20.2381H5.95238V23.8095ZM11.9048 23.8095H7.14286V20.2381H11.9048V23.8095ZM17.8571 23.8095H13.0952V20.2381H17.8571V23.8095ZM11.0119 15.4524V18.381L5.19048 12.5L11.0119 6.61905V9.60714C15.5 9.78571 19.4286 12.5714 19.8214 18.3333C17.6071 15.5357 14.7024 14.6905 11.0119 15.4524ZM23.8095 22.0238C23.8095 23.0119 23.0119 23.8095 22.0238 23.8095H19.0476V20.2381H23.8095V22.0238ZM23.8095 4.7619H19.0476V1.19048H22.0238C23.0119 1.19048 23.8095 1.9881 23.8095 2.97619V4.7619Z"
                fill="white"
            />
        </svg>
    );
};

export default ResponsesTabIcon;
