import { ReactChild } from "react";
import MediaQuery from "react-responsive";

interface ResponsiveProps {
    children: ReactChild;
    handleMediaQueryChange?: () => void;
}

export const Mobile = ({ children, handleMediaQueryChange }: ResponsiveProps) => {
    return (
        <MediaQuery maxWidth={992} onChange={handleMediaQueryChange}>
            {children}
        </MediaQuery>
    );
};

export const Desktop = ({ children, handleMediaQueryChange }: ResponsiveProps) => {
    return (
        <MediaQuery minWidth={993} onChange={handleMediaQueryChange}>
            {children}
        </MediaQuery>
    );
};
