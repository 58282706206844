import { Backdrop, CircularProgress } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { Paths, replaceEventIdInPath } from "../../Common/AppRoutes";
import HttpService from "../../Services/HttpService";
import ToastService from "../../Common/ToastService";
import { isWhitelistedEmail } from "../../Common/GuardedRoute";
import { AuthContext } from "../../Contexts/AuthContext";

const progress = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    height: "calc(100vh - 60px)"
};

const Extension = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const ref = useRef(false);

    const createEventFromExtension = async () => {
        try {
            if (!ref.current) {
                if (!authContext.user || !isWhitelistedEmail(authContext, authContext.user.email)) {
                    // redirect to landing page if user is not logged in or the user is not whitelisted
                    return navigate(Paths.landing, { replace: true });
                }
                ref.current = true;
                const data = await HttpService.postFormData(
                    `/v1/event/create`,
                    {
                        creator_id: authContext.user.userId,
                        permissions: {
                            is_public: false,
                            is_active: true,
                            is_creator_a_contributor: true
                        },
                        title: ""
                    },
                    false,
                    true
                );
                return navigate(replaceEventIdInPath(Paths.recordEmovid, data.event.id));
            }
        } catch (error: any) {
            ToastService.alertableError("Unable to create event", error);
        }
    };

    useEffect(() => {
        createEventFromExtension();
    }, []);

    return (
        <div style={progress}>
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default Extension;
