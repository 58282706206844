import { lazy } from "react";

export const lazyWithRetry = (componentImport: any) =>
    lazy(async () => {
        let pageForceRefreshCount = parseInt(window.localStorage.getItem("page-force-refresh-count") || "0");
        try {
            const component = await componentImport();
            window.localStorage.setItem("page-force-refresh-count", "0");
            return component;
        } catch (error: any) {
            console.log("Error loading chunk caught");
            if (pageForceRefreshCount < 2) {
                pageForceRefreshCount++;
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem("page-force-refresh-count", `${pageForceRefreshCount}`);
                return window.location.reload();
            }
            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
