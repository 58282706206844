import { useEffect, useRef, useState } from "react";

export const useComponentDidMount = (handler: any) => {
    return useEffect(() => {
        return handler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useComponentDidUpdate = (handler: any, deps: any) => {
    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        return handler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useComponentWillUnmount = (handler: any) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(() => handler, []);
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
}
