import Popup from "./Popup";

export const CookiesDisabledPopup = () => {
    return (
        <Popup isOpen={true} className="cookies-disabled-popup" onClose={() => {}}>
            <div className="container p-3">
                <h3 className="text-dark m-auto pb-3 text-white text-center">Cookies Disabled</h3>
                <p className="text-dark m-auto pt-2 pb-3 text-white">Please enable cookies on your browser. We do not currently support disabled cookie mode.</p>
            </div>
        </Popup>
    );
};
