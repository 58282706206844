import { FC } from "react";
import { useNavigate } from "react-router";
import { Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Paths } from "../../Common/AppRoutes";
import { useUnviewedCountQuery } from "../../Common/Helper";

interface NotificationsIconButtonProps {}

const NotificationsIconButton: FC<NotificationsIconButtonProps> = (props: NotificationsIconButtonProps) => {
    const navigate = useNavigate();
    const { data } = useUnviewedCountQuery({ refetchOnWindowFocus: false });

    const dashboardClicked = () => {
        navigate(`${Paths.dashboard}?selected_filter=unviewed`);
    };

    return (
        <IconButton className="ms-2" onClick={dashboardClicked}>
            <Badge color="error" variant="dot" invisible={data?.count === 0}>
                {data?.count === 0 && <NotificationsNoneIcon className="svg-shadow" />}
                {data?.count !== 0 && <NotificationsIcon className="svg-shadow" />}
            </Badge>
        </IconButton>
    );
};

export default NotificationsIconButton;
