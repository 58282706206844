import { Navigate, useLocation } from "react-router";
import { AuthData, useAuth } from "../Contexts/AuthProvider";
import { Paths } from "./AppRoutes";
import { IUserInfo } from "../Interfaces/IUserInfo";

export const isWhitelistedUser = (authContext: AuthData | { user: IUserInfo | undefined; isWhitelisted: boolean }): boolean => {
    let user = authContext.user || { email: "" };
    if (user && user.email) {
        return isWhitelistedEmail(authContext, user.email);
    } else return false;
};

export const isWhitelistedEmail = (authContext: AuthData | { user: IUserInfo | undefined; isWhitelisted: boolean }, email: string): boolean => {
    let isEmailWhitelisted = !!authContext.isWhitelisted;
    let emailDomain = (email || "").split("@").pop() || "";
    let isWhitelistedDomain = emailDomain.indexOf("gratiphied.com") >= 0 || emailDomain.indexOf("emovid.com") >= 0;
    return isEmailWhitelisted || isWhitelistedDomain;
};

export const isPersonaUser = (authContext: AuthData): boolean => {
    const user = authContext.user || { email: "" };
    if (user && user.email) {
        const emailDomain = (user.email || "").split("@").pop() || "";
        const isWhitelistedDomain = emailDomain.indexOf("emovid.com") >= 0;
        return isWhitelistedDomain;
    } else return false;
};

export const customAuthenticatorByRoutes = (user: any, path: string, whitelistedEmails: any): boolean => {
    if (!user) return false;
    return true;
};

export const GuardedRoute = ({ children }: any) => {
    const { user, isWhitelisted } = useAuth();
    const location = useLocation();

    const isAuthenticated = customAuthenticatorByRoutes(user, location.pathname, isWhitelisted);

    // redirect to mfa validate page if user is not mfa authenticated
    if ([Paths.validateMFA].indexOf(location.pathname) === -1 && user && user.mfaEnabled && !user.mfaVerified) {
        return <Navigate to={Paths.validateMFA} replace state={{ path: location.pathname }} />;
    }

    // prevent going to mfa validate page if user is already validated using code
    if (location.pathname === Paths.validateMFA && user && user.mfaEnabled && user.mfaVerified) {
        return <Navigate to={sessionStorage.getItem("redirectPath") || "/"} replace state={{ path: location.pathname }} />;
    }

    // prevent going to mfa validate page if user does not have mfa enabled
    if (location.pathname === Paths.validateMFA && user && !user.mfaEnabled) {
        return <Navigate to={sessionStorage.getItem("redirectPath") || "/"} replace state={{ path: location.pathname }} />;
    }

    return isAuthenticated ? children : <Navigate to={Paths.landing} replace state={{ path: location.pathname }} />;
};
